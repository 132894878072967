import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3e3842',
    },
    secondary: {
      main: '#ca304c',
    },
  },
  typography: {
    body2: {
      fontSize: '0.875rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1100,
      lg: 1500,
      xl: 2000,
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 8px',
          fontSize: '0.875rem',
          '@media (max-width: 2000px)': {
            fontSize: '0.8125rem',
          },
        },
        head: {
          backgroundColor: '#3e3842',
          color: '#ffffff',
          fontWeight: 700,
          whiteSpace: 'nowrap',
        },
      },
    },
  },
});

export default theme;
