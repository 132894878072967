import { Paper } from '@mui/material';
import React from 'react';
import MaterialTable from '@material-table/core';
import { IMaterialTableProps } from './OliMaterialTable.types';
import theme from '../../themes/themes';

const OliMaterialTable: React.FunctionComponent<IMaterialTableProps> = (props) => {
  const {
    data,
    columns,
    actions,
    loading,
    title,
    pagesize,
    search,
    selectable,
    filter,
    tableRef,
    header,
    pageSizeOptions,
    maxBodyHeight,
    onRowClick,
  } = props;

  const columnStyle = {
    headerStyle: {
      fontWeight: 'bold',
      fontSize: theme.typography.body2.fontSize,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
  };

  const showSelection = actions && actions?.length > 0 && selectable !== false;
  const tableTitle = title || '';
  const tablePageSize = pagesize || 20;
  const tablePageSizeOptions = pageSizeOptions || [5, 10, 20, 50, 100] as number[];

  const options = {
    selection: showSelection,
    search: search || false,
    pageSize: tablePageSize,
    pageSizeOptions: tablePageSizeOptions,
    ...columnStyle,
    filtering: filter || false,
    header: header === undefined,
    toolbar: showSelection || tableTitle !== '',
    maxBodyHeight: maxBodyHeight as string,
    onRowClick: onRowClick || null,
    padding: 'dense',
    rowStyle: {
      fontSize: theme.typography.body2.fontSize,
      padding: (theme.components?.MuiTableCell?.styleOverrides?.root as { padding?: string })?.padding ?? '16px',
    },
  };

  return (
    <Paper sx={{ overflow: 'hidden' }}>
      <MaterialTable
        tableRef={tableRef}
        title={tableTitle}
        columns={columns}
        data={data}
        options={options}
        actions={actions}
        isLoading={loading}
        onRowClick={onRowClick}
      />
    </Paper>
  );
};

export default OliMaterialTable;
