import React, { useContext } from 'react';
import OliMaterialTable from 'components/OliMaterialTable/OliMaterialTable';
import {
  Action, MTQuery,
} from 'components/OliMaterialTable/OliMaterialTable.types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OliAxios from 'api/util/OliAxios';
import { getConfig } from 'api/util/getConfig';
import UserContext from 'context/UserContext';
import {
  useNavigate, useParams,
} from 'react-router-dom';

import Header from 'components/Header/Header';
import {
  Button,
  Container,
} from '@mui/material';

import {
  MaterialTableProps, Query,
} from '@material-table/core';
import {
  Scrub,
} from './Scrub.types';
import { patchScrub } from './Scrub.api';

const Scrubs: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const tableRef = React.useRef();
  const token = useContext(UserContext)?.token;
  const { matterUUID } = useParams();

  // eslint-disable-next-line
  const constructUrl = (query: Query<object>): string => {
    const qsp: string[] = [];
    const url = '/scrub/list';
    if (query.filters) {
      query.filters.forEach((f) => {
        qsp.push(`${f.column.field as string}=${f.value as string}`);
      });
    }
    if (matterUUID) {
      qsp.push(`matterUUID=${matterUUID}`);
    }
    qsp.push(`per_page=${query.pageSize}`);
    qsp.push(`page=${query.page + 1}`);

    if (query.orderBy && query.orderDirection) {
      qsp.push(`order=${query.orderBy.field as string}`);
      qsp.push(`orderDirection=${query.orderDirection}`);
    }

    const queryStringParams = qsp.join('&');
    if (queryStringParams.length > 0) {
      return `${url}?${queryStringParams}`;
    }
    return url;
  };

  const getList = (): void => {
    if (tableRef && tableRef.current) {
      (tableRef.current as MaterialTableProps<Record<string, unknown>>)?.onQueryChange?.();
    }
  };

  const renderExclusionItems = (exclusionItems: string[]): JSX.Element[] => exclusionItems.map((ei) => (
    <span key={ei}>
      {ei}
      <br />
    </span>
  ));

  const handleClose = (e: Record<string, unknown>, reason: string): void => {
    if (reason !== 'backdropClick') {
      getList();
    }
  };

  const handleReviewClick = async (rowData: Scrub, action: string): Promise<void> => {
    const params = {
      action,
      scrub_uuid: rowData.scrub_uuid,
      matterID: rowData.matter_uuid,
      external_account_id: rowData.external_account_id,
      closedReasonCode: rowData.scrub_type,
      closedCommentsText: 'Scrub Approval',
    };

    const result = await patchScrub(token, params);
    if (result) {
      handleClose({}, 'button');
    }
  };

  const columns = [
    {
      title: 'Scrub Review',
      field: 'actions',
      filtering: false,
      render: (rowData: Scrub) => (
        <div>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleReviewClick(rowData, 'approved')}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleReviewClick(rowData, 'rejected')}
            style={{ marginLeft: 8 }}
          >
            Reject
          </Button>
        </div>
      ),
    },
    {
      title: 'Matter Reference',
      field: 'external_account_id',
    },
    {
      title: 'Scrub Event', field: 'scrub_type',
    },
    {
      title: 'Exclusion Items',
      field: 'exclusion_items',
      render: (rowData: Scrub) => renderExclusionItems(rowData.exclusion_items),
    },
    {
      title: 'Generated On', field: 'generated_on',
    },
    {
      title: 'Status', field: 'status',
    },
  ];

  const openMatter = (rowData: Scrub): void => {
    navigate(`/matter/${rowData.matter_uuid}`);
  };

  const actions: Action[] = [
    {
      icon: () => <OpenInNewIcon />,
      tooltip: 'View',
      onClick: (_e, rowData) => openMatter(rowData as Scrub),
    },
  ];

  return (
    <Container maxWidth={false}>
      <Header text="Scrubs" period />
      <Container maxWidth="lg">
        <div>
          <OliMaterialTable
            tableRef={tableRef}
            columns={columns}
            data={(query) => new Promise((resolve) => {
              const url = constructUrl(query);
              OliAxios.get(url, { ...getConfig(token || '') })
                .then((response) => response.data as unknown as MTQuery)
                .then((result) => {
                  resolve({
                    data: result.data,
                    page: parseInt(result.page, 10) - 1,
                    totalCount: parseInt(result.total, 10),
                  });
                })
                .catch((e) => console.error(e));
            })}
            pagesize={20}
            actions={actions}
            selectable={false}
            filter
          />
        </div>
      </Container>
    </Container>
  );
};

export default Scrubs;
